import * as React from "react"

import { useStaticQuery, PageProps, Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Features from "../components/features"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string;
    }
  }
}

const AboutPage: React.FC<PageProps<DataProps>> = (props) => (
  <Layout {...props}>
    <Seo title="About" />
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-12 mx-auto flex flex-col">
        <div className="lg:w-4/6 mx-auto">
          <div className="rounded-lg h-64 overflow-hidden">
            <img alt="content" className="object-cover object-center h-full w-full" src="/octavian-dan-iY6QMkP66mI-unsplash.jpg" />
          </div>
          <div className="flex flex-col sm:flex-row mt-10">
            <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
              <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex flex-col items-center text-center justify-center">
                <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Morag Cahir</h2>
                <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                <p className="text-base">Bsc., Post Grad Cert., Dip in Clinical and Advanced Hypnosis, Hypnotherapy in Practice Diploma, Practitioner in Neuro Linguistic Programme PNLP., Cosca Certificate in Counselling Skills.</p>
              </div>
            </div>
            <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
              <p className="leading-relaxed text-lg mb-4">Morag  previously  worked  in the NHS as a qualified nurse and gained experience  in many  areas of health care including;  nutrition, pain and symptom management, anxiety, menopausal  issues and insomnia. Also experience with psychological challenges such as resilience building, adjustment strategies and coping with loss and bereavement.</p>
              <p className="leading-relaxed text-lg mb-4">After having personal experience of the remarkable benefits of hypnotherapy leading to transformations in her life,  Morag made the decision to change careers and become a Clinical Hypnotherapist. She qualified after training with  the UK Academy Scotland. Morag has experience in a wide range of hypnotherapy techniques.</p>
              {/*<a className="text-indigo-500 inline-flex items-center">Learn More
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>*/}
            </div>
          </div>
        </div>
        <Link to="/services" className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Services</Link>
      </div>
    </section>

    <Features elements={['why', 'what', 'zoom']} />
  </Layout>
)

export default AboutPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
      siteMetadata {
        title
      }
    }
  }
`
